import { Utils } from 'Shared/utils';
import { MediaTarget } from './target';
import { mediaPrompt } from 'Shared/Layout/mediaPrompt';
import { MediaItemData } from './item';
import { MediaItemImage } from './item_image';
import { MediaItemDocument } from './item_document';
import { MediaItemEmbed } from './item_embed';
import { MediaItemVideo } from './video_item';

/**
 * Represents a location that a MediaItem can be placed on the page.
 *
 * Events:
 *    browse(): fired when an action is taken by the user that indicates they
 *       want to change the MediaItem which is displayed in this target.
 *
 *    itemChanged(oldItem, newItem): fires when the current mediaItem is
 *       replaced by another one.
 *
 *    fieldUpdated(): fires when the form field is updated with a new mediaid.
 *       This will fire when a different mediaItem is attached or when the
 *       current mediaItem is modified(cropped, markers)
 */
export const TextMediaTarget = (window.TextMediaTarget = new Class({
   name: 'TextMediaTarget',
   Extends: MediaTarget,

   initialize: function (el, options) {
      this.parent(el, options);
      this._initializeUI(el);
   },

   _initializeUI: function (el) {
      if (App.featureMediaManagerV2) {
         this.addEvent('browse', () => {
            mediaPrompt('wiki_media').then(data => {
               if (data) {
                  const types = {
                     Document: MediaItemDocument,
                     GuideImage: MediaItemImage,
                     GuideEmbedObject: MediaItemEmbed,
                     GuideVideoObject: MediaItemVideo,
                  };
                  data = new MediaItemData(data);
                  let classToCreate = types[data.type()];
                  let dataPromise = new Future();
                  const mediaItem = new classToCreate(data, {}, dataPromise);
                  this._immediatelyAttach(mediaItem);
                  this.fireEvent('itemChanged', [mediaItem, null]);
               }
            });
         });
      } else {
         this.addEvent('browse', MediaTarget._initiateBrowsing);
      }
   },

   /**
    * Display the given media item in this target.
    */
   _displayItem: function (mediaItem) {
      let create = Utils.createElements;
      let self = this;

      let wrapper = create({
         tag: 'div',
         c: 'row attachment-container',
      });

      if (!mediaItem) {
         self._empty();
         let contents = create({
            tag: 'a',
            c: 'viewLink add-document hidden',
            text: _js('Add New'),
         })
            // Inform listeners that the user wants to browse their media
            .addEvent('browse', function () {
               self.fireEvent('browse');
            });

         wrapper.grab(contents);
         self.element.grab(wrapper);
         return;
      }

      let documentGroup = mediaItem.data.document_group();
      let documentExtension = mediaItem.data.document_extension();
      let officeDocumentType = mediaItem.data.document_type();

      let faClassIcon = mediaItem.getIconClassname();

      let docIcon = create({
         c: faClassIcon,
      });

      self._empty();
      let info = create({
         tag: 'div',
         c: 'column',
      });

      let fileLink = create({
         tag: 'a',
         href: mediaItem.data.view_url(),
         target: '_blank',
      });

      let filename = create({
         tag: 'p',
         text: mediaItem.data.title() || mediaItem.data.filename(),
      });

      let featureDocument = create({
         tag: 'a',
         c: 'feature-doc-button button button-small button-transparent',
         text: _js('Feature'),
      });

      let editDocument = create({
         tag: 'a',
         c: 'edit-doc-button button button-small button-transparent',
         text: _js('Edit'),
         href: mediaItem.data.edit_url(),
         target: '_blank',
      });

      let removeLink = create({
         tag: 'a',
         html: App.iconTimes,
         c: 'removeLink delete',
      }).addEvent('click', function () {
         self.attachItem(null);
      });

      let removeDocContainer = create({
         c: 'remove-doc-container',
      });

      self.element.grab(wrapper);

      removeDocContainer.grab(removeLink);

      fileLink.grab(filename);
      info.grab(fileLink);

      wrapper.grab(docIcon);
      wrapper.grab(info);
      wrapper.grab(editDocument);

      if (documentGroup === 'pdf') {
         wrapper.grab(featureDocument);
      }

      wrapper.grab(removeDocContainer);
   },
}));
