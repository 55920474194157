import { MediaTarget } from 'Shared/FrameModules/MediaLibrary/target';
import { FrameModules } from 'Shared/frame_modules';

FrameModules.add('WikiImageFrameModule', function () {
   when($('pageImages'), function () {
      WikiImageManager.initialize();

      if (!App.mediaManagerV2) {
         when($('wikiSummaryImage'), function (elementId) {
            let targetElement = $(elementId);
            if (!targetElement) {
               return;
            }
            MediaTarget.createFromElement(targetElement);
         });
      }
   });
});

// eslint-disable-next-line no-var
export var WikiImageManager = (window.WikiImageManager = {
   initialize: function () {
      let self = this;
      this.thumbsContainer = $('pageImages');
      this.imageMeta = $$('.imageMeta');

      // Get image data for each of the images attached to this wiki, and then
      let wikiid;
      if ((wikiid = $('wikiid').get('text'))) {
         new Request.AjaxIO('getWikiImages', {
            onSuccess: function (images) {
               images.map(function (itemData) {
                  self.addImage(itemData.imageid, itemData.thumbnail);
               });
            },
         }).send(wikiid);
      }
   },

   addImage: function (imageid, url) {
      const el = this.getImageElement(imageid, url);
      this.thumbsContainer.appendChild(el);

      let hideEvent,
         text = this.imageMeta.getFirst();

      // Show the imageid for 5 seconds on hover
      el.addEvent('mouseenter', function () {
         clearTimeout(hideEvent);
         text.set('text', `image: ${imageid}`);
         text.makeVisible();
      });
      this.imageMeta.addEvents({
         mouseenter: function () {
            clearTimeout(hideEvent);
            text.makeVisible();
         },
         mouseleave: function () {
            hideEvent = text.makeInvisible.delay(5000, text);
         },
      });
   },

   getImageElement: function (imageid, url) {
      const div = document.createElement('div');
      div.className = 'mediaItem mediaImage';
      div.dataset.imageid = imageid;
      div.id = `GuideImage-${imageid}`;

      const img = document.createElement('img');
      img.className = 'thumbnail';
      img.src = url;
      div.appendChild(img);

      return div;
   },
});
