export default function mediaItemToWikiText(mediaItem: any): string {
   if (mediaItem.type === 'GuideImage') {
      return `[image|${mediaItem.imageid}]`;
   } else if (mediaItem.type === 'Document') {
      return `[document|${mediaItem.documentid}]`;
   } else if (mediaItem.type === 'GuideVideoObject') {
      return `[video|${mediaItem.videoid}]`;
   } else if (mediaItem.type === 'GuideEmbedObject') {
      return `[embed|${mediaItem.videoid}]`;
   }

   return '';
}
